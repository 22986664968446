<template>
    <div>
        <Header title="Health Festival Amsterdam" lead="9 t/m 13 april 2025" sublead="Een week volledig in het teken van body en mind gezondheid. Met meer dan 500+ kosteloze activiteiten. Doe je mee?" link="/host/health-festival-7d982ae0" button="Tickets & Info" :header="require('@/assets/stock/health-festival-2025.jpg')" />

        <div class="categories pt-5 pb-5 mb-5">
            <b-container fluid>
                <h2 class="mb-4">Categorieën</h2>
                <CategoryListing :city="city" />
            </b-container>
        </div>

        <div class="current mb-5">
            <b-container fluid>
                <div class="mb-4">
                    <span class="h2">Populair in</span>
                    <span class="h3 ml-3">
                        <font-awesome-icon icon="chevron-down" />
                    </span>
                    <span class="d-block d-md-inline-block ml-md-2">
                        <vue-bootstrap-autocomplete
                            class="location-search-input"
                            :data="cities"
                            v-model="city"
                            :serializer="s => s.name"
                            placeholder="Amsterdam"
                            @input="getCities"
                            @hit="selectedCity"
                        >
                        </vue-bootstrap-autocomplete>
                    </span>
                </div>
                <PopularListing :city="city" />
            </b-container>
        </div>
        <div class="recommended pt-5 pb-5 mb-5">
            <b-container fluid>
                <h2 class="mb-4">Meer evenementen</h2>
                <MoreListing :city="city" />
                <div class="text-center">
                    <b-button variant="outline-primary" to="/coming-soon">Meer evenementen</b-button>
                </div>
            </b-container>
        </div>
        <div class="organizers mb-5">
            <b-container fluid>
                <h2 class="mb-4">Uitgelichte organisatoren</h2>
                <OrganizersListing :city="city" />
            </b-container>
        </div>
    </div>
  </template>
  
<script>
    import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';
    import CategoryListing from '@/components/CategoryListing';
    import PopularListing from '@/components/PopularListing'
    import MoreListing from '@/components/MoreListing'
    import OrganizersListing from '@/components/OrganizersListing'
    import Header from '@/shared/components/Header'

    export default {
        metaInfo: {
            title: 'Ontdek de beste lokale evenementen',
            meta: [
                { name: 'description', content: 'Vind kaartjes voor jouw volgende onvergetelijke ervaring. Blader door concerten, workshops, yogaklassen, liefdadigheidsevenementen, food- en muziekfestivals, en nog veel meer activiteiten om te...' },
            ]
        },
        components: {
            CategoryListing,
            PopularListing,
            MoreListing,
            OrganizersListing,
            VueBootstrapAutocomplete,
            Header,
        },
        data() {
            return {
                slide: 0,
                sliding: null,
                cities: [],
                city: 'Amsterdam',
            }
        },
        methods: {
            async getCities(query) {
                await this.$axios.get("https://api.tixgo.nl/city?filter[name]=" + query)
                    .then( response => {
                        if (response.data) {
                            this.cities = response.data;
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            selectedCity: function(item) {
                this.city = item.name;
                this.city = item.lat;
                this.city = item.lng;
                //this.$emit('input', this.value )
            }
        }
    }
</script>

<style scoped>
    .categories,
    .recommended{
        background-color:#eee;
    }
</style>

<style>
    .location-search-input input{
        background-color:transparent;
        border:0;
        box-shadow:none;
        border-radius:0;
        border-bottom: 2px solid rgba(0,0,0,0.3);
        font-size:1.75rem;
        padding:0;
    }
    .location-search-input input:hover,
    .location-search-input input:focus{
        background-color:transparent;
        border:0;
        box-shadow:none;
        border-bottom: 2px solid rgba(0,0,0,0.3);
    }
    .vbt-autcomplete-list{
        padding-top:0 !important;
    }
</style>